import { useEffect, useId, useState } from 'react';
import { FaExclamationCircle } from 'react-icons/fa';
import { Tooltip } from 'react-tooltip';

import { scouting } from '@atlanta-hawks/bbops-client-js';
import styled from '@emotion/styled';

import { Button } from '../../../components/buttons';
import Dropdown from '../../../components/dropdown';
import List, { ListItem } from '../../../components/list';
import { SpinnerButton } from '../../../components/spinner';
import { Subheading, Text } from '../../../components/typography';
import { checkRanks, useScouting } from '../../../contexts/scouting';
import { HawksError } from '@hawks-ui/errors';

const StyledPublishModalForm = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledPublishModalControls = styled.div`
  margin-top: 0.5rem;
  display: flex;
`;

const StyledErrText = styled(Text)`
  color: ${({ theme }) => theme.colors.builtins.grays.lighter};
  margin-left: 0.5rem;
`;

const StyledPublishingLabel = styled.input`
  margin-right: 0.309rem;
`;

const StyledUserDisplay = styled.span`
  margin-left: 0.25rem;
  display: flex;
  align-items: center;
`;

const StyledExclamationCircle = styled(FaExclamationCircle)`
  margin-left: 0.25rem;
  color: ${({ theme }) => theme.colors.builtins.yellows.base};
`;

const StyledKicker = styled.div<{ isPublic: boolean }>`
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  padding: 0.5rem;
  border-top: 1px solid ${({ theme }) => theme.colors.site.navbar.border};
  border-bottom: 1px solid ${({ theme }) => theme.colors.site.navbar.border};
  background-color: ${({ theme, isPublic }) =>
    isPublic ? theme.colors.builtins.greens.faded : theme.colors.builtins.blues.faded};
`;

const StyledLists = styled.div`
  display: flex;
`;

interface Props {
  existingLabels: string[];
  pastRanks: scouting.PastRanks[];
  submitDeadline?: scouting.SeasonSubmitDeadline;
}

function DeadlineManagerPublishModalForm({ existingLabels, pastRanks, submitDeadline }: Props) {
  const idList = useId();

  const { api, compositeRanksFetch, pastRanksFetch, playersById } = useScouting();

  const [err, setErr] = useState<HawksError>();

  const readyRanks = pastRanks
    .map(ranks => {
      const check = checkRanks(
        ranks.players,
        playersById,
        ranks.players.reduce((acc, r) => ({ ...acc, [r.playerId]: r.meta }), {}),
        submitDeadline,
      );
      return { ranks, check };
    })
    .filter(({ check }) => check.checks.size);
  const readyUsers = readyRanks.map(({ ranks }) => ranks.email);

  const [limit, setLimit] = useState(0);
  const [label, setLabel] = useState('');
  const [emails, setEmails] = useState(readyUsers.map(() => false));
  const [viewableBy, setViewableBy] = useState(readyUsers.map(() => false));

  const [allEmails, setAllEmails] = useState(false);
  const [isPublic, setIsPublic] = useState(true);

  const handleSelectUser = (e: React.ChangeEvent<HTMLInputElement>) => {
    const checks = [...emails];

    const idx = readyUsers.indexOf(e.target.value);
    if (idx < 0) {
      return;
    }

    checks[idx] = e.target.checked;
    setEmails(checks);
    setAllEmails(checks.every(c => c));
  };

  const handleSelectViewableBy = (e: React.ChangeEvent<HTMLInputElement>) => {
    const checks = [...viewableBy];

    const idx = readyUsers.indexOf(e.target.value);
    if (idx < 0) {
      return;
    }

    checks[idx] = e.target.checked;
    setViewableBy(checks);
    setIsPublic(checks.every(c => c));
  };

  const handleSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmails(readyUsers.map(() => e.target.checked));
    setAllEmails(e.target.checked);
  };

  const handleMakePublic = (e: React.ChangeEvent<HTMLInputElement>) => {
    setViewableBy(readyUsers.map(() => !e.target.checked));
    setIsPublic(e.target.checked);
  };

  useEffect(() => {
    if (!submitDeadline?.size) {
      return;
    }
    setLimit(submitDeadline.size);
  }, [submitDeadline?.size]);

  return (
    <StyledPublishModalForm>
      <Subheading as="h6">{`Publish for date: ${submitDeadline?.date?.toLocaleDateString() || ''}`}</Subheading>
      <Dropdown
        value={`${limit}`}
        onChange={e => {
          const l = parseInt(e.target.value, 10);
          if (!l) {
            return;
          }
          setLimit(l);
        }}
      >
        {Array.from({ length: submitDeadline?.size || 0 }).map((_, i) => (
          <option key={`top_${i}`} value={(submitDeadline?.size || 0) - i}>{`Top ${(submitDeadline?.size || 0) - i}`}</option>
        ))}
      </Dropdown>
      <StyledPublishModalControls>
        <StyledPublishingLabel
          list={idList}
          type="text"
          placeholder="Publishing label"
          value={label}
          onChange={e => setLabel(e.target.value)}
        />
        {!!existingLabels.length && (
          <datalist id={idList}>
            {existingLabels.map(label => (
              <option key={label} value={label} />
            ))}
          </datalist>
        )}
        <SpinnerButton
          waitForMillis={618}
          onClick={() => {
            setErr(undefined);
            api
              .admin()
              .compositeRanks()
              .publish(
                submitDeadline?.period || 0,
                label,
                emails
                  .map((u, i) => ({ ok: u, email: readyUsers[i] }))
                  .filter(({ ok }) => ok)
                  .map(({ email }) => email),
                viewableBy
                  .map((u, i) => ({ ok: u, email: readyUsers[i] }))
                  .filter(({ ok }) => ok)
                  .map(({ email }) => email),
                limit,
              )
              .then(() => Promise.all([pastRanksFetch(), compositeRanksFetch()]))
              .catch(e => {
                const err = HawksError.make(e);
                setErr(err);
              });
          }}
        >
          <Button disabled={!submitDeadline} nomargin shade="reds">
            Publish Ranks
          </Button>
        </SpinnerButton>
        {err && <StyledErrText nomargin>{`${err.code}: ${err.message}`}</StyledErrText>}
      </StyledPublishModalControls>
      <StyledKicker isPublic={isPublic}>
        {isPublic && <Text nomargin>These rankings will be viewable by everyone</Text>}
        {!isPublic && <Text nomargin>These rankings are only vieable by certain users</Text>}
      </StyledKicker>
      <StyledLists>
        <List flush>
          <ListItem>
            <input type="checkbox" checked={allEmails} onChange={handleSelectAll} />
            &nbsp;Select all
          </ListItem>
          {readyRanks.map(({ ranks, check }, i) => (
            <ListItem key={ranks.id + '_published'} display="flex">
              <input type="checkbox" value={ranks.email} checked={emails[i]} onChange={handleSelectUser} />
              <StyledUserDisplay>
                {ranks.email}
                {!check.checks.positions && (
                  <StyledExclamationCircle
                    data-tooltip-id="publish-modal-tooltip"
                    data-tooltip-content={JSON.stringify(check.positions, null, 2)}
                  />
                )}
                {!check.checks.outcomes && (
                  <StyledExclamationCircle
                    data-tooltip-id="publish-modal-tooltip"
                    data-tooltip-content={`Set ${check.outcomes.total} outcome(s)`}
                  />
                )}
              </StyledUserDisplay>
            </ListItem>
          ))}
        </List>
        <List flush>
          <ListItem>
            <input type="checkbox" checked={isPublic} onChange={handleMakePublic} />
            &nbsp;Make public
          </ListItem>
          {readyRanks.map(({ ranks, check }, i) => (
            <ListItem key={ranks.id + '_viewable_by'} display="flex">
              <input type="checkbox" value={ranks.email} checked={viewableBy[i]} onChange={handleSelectViewableBy} />
              {!isPublic && viewableBy[i] && <span>Can view</span>}
            </ListItem>
          ))}
        </List>
      </StyledLists>
      <Tooltip id="publish-modal-tooltip" place="right-end" />
    </StyledPublishModalForm>
  );
}

export default DeadlineManagerPublishModalForm;
